<template>
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/4.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item">搜索结果</span>
      </div>
        <div class="nuox-module-search-content">
            <div class="nuox-news-content-list">
              <div class="nuox-news-content-item" v-for="(item,index) in searchList" :key="index">
                <div class="nuox-news-date">
                    <div class="nuox-news-day">{{item.day}}</div>
                    <div class="nuox-news-month">{{item.month}}</div>
                </div>
                <div class="news-content-left">
                  <img :src="item.imgSrc">
                </div>
                <div class="news-content-right">
                  <p class="tt" v-if="$i18n.locale=='zh'" v-html="item.titleNameZh"></p>
                  <p class="tt" v-if="$i18n.locale=='en'" v-html="item.titleNameEn"></p>
                  <p class="cc" v-if="$i18n.locale=='zh'" v-html="item.contentZh"></p>
                  <p class="cc" v-if="$i18n.locale=='en'" v-html="item.contentEn"></p>
                  <div class="news-more">
                    <button class="nuox-new-button" @click="itemClick(item)" >{{$t('lang.button.one')}}</button>
                  </div>
                </div>
              </div>
              <div style="text-align:center;">
                  <el-pagination
                    :small="true"
                    :background="true"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[5,10,20,30,50]"
                    :page-size="pageCount"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount">
                  </el-pagination>
              </div>
            </div>
        </div>
        <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  data:function() {
    return {
        pageNum:1,
        pageCount:5,
        totalCount:0,
        searchList:[],
        searchStr:''
    };
  },
  watch: {
      '$route' (to, from) { //监听路由是否变化
        if(to.query.key != from.query.key){
          var searchStr = this.$route.query.key;
          this.pageNum=1;
          this.pageCount=10;
          this.totalCount=0;
          this.searchStr = searchStr;
          this.init();
        }
      }
  },
  created() {
    if(this.$route.query) {
       var searchStr = this.$route.query.key;
       this.searchStr = searchStr;
       this.init();
    }
  },
  methods:{
    async init(){
      var searchStr = this.searchStr;
       this.searchList=[];
      if(searchStr==""){
        this.pageNum=1;
          this.pageCount=10;
          this.totalCount=0;
      }
      var that = this;
      var baseUrl = this.$baseURL;
      var langType = localStorage.getItem('locale');
      const { data: res } = await this.$http.get(
        "webSite/find_data_list?pageNum="+that.pageNum+"&pageSize="+that.pageCount+"&langType="+langType+"&search="+searchStr
      );
      if(res.code==0){
        var data = res.data.data.rows;
        that.totalCount = res.data.data.total;
        for(var key in data){
          data[key].contentZh = that.$getHtmlToText(data[key].contentZh);
          data[key].contentEn =  that.$getHtmlToText(data[key].contentEn);
          data[key].day = that.$moment(data[key].contentDate).format('DD');
          data[key].month = that.$moment(data[key].contentDate).format('YYYY-MM');
          if(data[key].attList&&data[key].attList.length>0){
            data[key].imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
          }
        }
        that.searchList = data;
      }
    },
    itemClick(item){ 
      var url1="/";
      if(item.tableType=='newIndustry'){//行业新闻
          url1="/news/industry";
      }else if(item.tableType=='newDynamic'){//公司动态
          url1="/news/company";
      }else if(item.tableType=='newPersonnel'){//员工风采
          url1="/news/staff";
      }
      this.$router.push({ 
            path: url1,
            query: {id:item.dataId}
      })
    },
    handleSizeChange(val) {
        this.pageCount = val;
        this.init();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.init();
      }
  }
}
</script>
<style>
@import '../assets/css/about.css';
@import '../assets/css/news.css';
.nuox-module-search-content{
  margin: 50px 0;
}
</style>